import Axios, { AxiosRequestConfig } from 'axios'

import qs from 'qs'
import isTokenValid from 'shared/isTokenValid'
import store from 'store'
import awaitUntilTokenIsRenewed from './awaitUntilTokenIsRenewed'

const addAuthorizationHeader = async (config: AxiosRequestConfig) => {
  const token = store.getState().auth.token
  const tokenPayload = store.getState().auth.tokenPayload
  const currentCondominium = store.getState().auth.currentCondominium

  if (!tokenPayload) return config

  if (!isTokenValid(tokenPayload)) {
    const newToken = await awaitUntilTokenIsRenewed()

    if (!newToken) return config
    config.headers['Authorization'] = `bearer ${newToken}`
    return config
  }

  config.headers['Authorization'] = `bearer ${token}`
  config.headers['X-CondominioId'] = currentCondominium

  return config
}

const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
})

api.interceptors.request.use(addAuthorizationHeader)
api.interceptors.request.use(config => {
  for (const key in config.data) {
    if (
      typeof config.data[key] === 'string' &&
      config.data[key].trim() === ''
    ) {
      delete config.data[key]
    }

    if (typeof config.data[key] === 'object') {
      for (const subKey in config.data[key]) {
        if (
          typeof config.data[key][subKey] === 'string' &&
          config.data[key][subKey].trim() === ''
        ) {
          delete config.data[key][subKey]
        }
      }
    }

    if (Array.isArray(config.data[key])) {
      for (const subKey in config.data[key]) {
        if (typeof config.data[key][subKey] === 'object') {
          for (const subSubKey in config.data[key][subKey]) {
            if (
              typeof config.data[key][subKey][subSubKey] === 'string' &&
              config.data[key][subKey][subSubKey].trim() === ''
            ) {
              delete config.data[key][subKey][subSubKey]
            }
          }
        }
      }
    }
  }

  return config
})
export default api
