import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useRouter from 'use-react-router'

import Grid from 'carbono/Grid'
import PageHeader from 'page/components/PageHeader'
import WorkForm from '../components/WorkForm'

import { AppLayout } from 'layout/reducers/layoutReducer'
import useLayout from 'layout/hooks/useLayout'

import useMessage from 'common/hooks/useToastMessage'
import usePrevious from 'common/hooks/usePrevious'
import useTypedSelector from 'common/hooks/useTypedSelector'

import { SaveWorkPayload } from 'work/api/workApi'
import { saveWorkStarted } from 'work/reducers/workReducer'

import { createSelectProcessByWorkId } from 'process/reducers/process'

const ConstructionEdit = () => {
  const { history, match } = useRouter<{ workId: string }>()
  const workId = match.params.workId

  const condominiumId = useTypedSelector(({ auth }) => auth.currentCondominium)
  const work = useTypedSelector(({ work }) => work.items[workId])
  const process = useTypedSelector(createSelectProcessByWorkId(workId))
  const orderBy = localStorage.getItem('work_table_order')

  useLayout({
    title: 'Editar processo',
    layout: AppLayout.Sidebar,
    breadcrumbs: [
      {
        title: 'Processos',
        route: `/condominiums/${condominiumId}/works/1?${orderBy}`
      },
      {
        title: `Processo #${process?.codigo ?? ''}`,
        route: `/work/${workId}`
      },
      { title: 'Editar' }
    ]
  })

  useMessage('work', workId)

  const dispatch = useDispatch()

  const status = useTypedSelector(({ work }) => work.status[workId])

  const previousStatus = usePrevious(status)

  useEffect(() => {
    if (status === 'Done' && previousStatus === 'Saving')
      history.push(`/work/${workId}`)
  }, [status])

  const onSave = (values: SaveWorkPayload) => {
    dispatch(saveWorkStarted(values))
  }

  return (
    <div>
      <PageHeader />
      <Grid mode='flex' flexDirection='column' mx={3}>
        {condominiumId && (
          <WorkForm
            onSave={onSave}
            status={status}
            condominiumId={condominiumId}
            initialValues={work}
          />
        )}
      </Grid>
    </div>
  )
}

export default ConstructionEdit
