import { Rules } from 'userPermissions/api/userPermissionApi'
import usePermission from 'userPermissions/hooks/usePermission'
import useTemPermissao from 'userPermissions/hooks/useTemPermissao'

function usePodeAcessarRelatorios() {
  const temAcessoManutencao = useTemPermissao(
    Rules.VisualizarRelatorioManutencao
  )

  const temAcessoOutrosRelatorios = usePermission([
    Rules.ViewReport,
    Rules.VisualizarRelatorioManutencao
  ])

  return temAcessoManutencao || temAcessoOutrosRelatorios
}

export default usePodeAcessarRelatorios
