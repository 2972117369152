import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface Permissao {
  grupo: string
  nome: string
  descricao: string
  permissao: string
  permissaoCodigo: number
}

export type Permissoes = Permissao[]

/**
 * Carrega lista de permissões do usuário dado um condominio
 */
export async function fetchPermissions(condominioId: string) {
  try {
    const { data } = await api.get<Permissoes>(
      `Usuario/${condominioId}/permissoes`
    )

    return data
  } catch (error) {
    throw createRequestError(error as any)
  }
}
