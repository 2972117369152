import * as yup from 'yup'

import { Box, Stack } from '@chakra-ui/core'
import { Panel, PanelBody } from 'carbono/Panel'
import React, { FC, useState } from 'react'
import { getErrorMessage, getErrorVisibility } from 'shared/formikHelpers'
import WorkStatus, { workStatusOptions } from 'work/enums/workStatus'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Block } from 'block/api/blockApi'
import Button from 'carbono/Button'
import Divider from 'carbono/Divider'
import FormItem from 'carbono/Forms/FormItem'
import { Input } from 'carbono/Forms/Input'
import useIsCondominioVertical from 'common/hooks/useIsCondominioVertical'
import useTypedSelector from 'common/hooks/useTypedSelector'
import useWhenDone from 'common/hooks/useWhenDone'
import { useFormik } from 'formik'
import { Lot } from 'lot/api/lotApi'
import { Street } from 'street/api/streetApi'
import { RequestError } from 'types/global'
import useRouter from 'use-react-router'
import BindLotOwnerToWork from 'user/components/BindLotOwnerToWork'
import { SaveWorkPayload } from 'work/api/workApi'
import BlockSelect from './selects/BlockSelect'
import LotSelect from './selects/LotSelect'
import StreetSelect from './selects/StreetSelect'

const validationSchema = yup.object({
  loteId: yup.string().required('Obrigatório'),
  areaAConstruir: yup
    .number()
    .min(1)
    .typeError('Obrigatório')
    .positive('Obrigatório')
    .required('Obrigatório'),
  numeroPavimento: yup
    .number()
    .min(1)
    .typeError('Obrigatório')
    .positive('Obrigatório')
    .required('Obrigatório'),
  tipoProcesso: yup
    .number()
    .required('Obrigatório')
    .min(1)
})

export interface ConstructionFormProps {
  condominiumId: string
  initialValues?: SaveWorkPayload
  onSave: (values: SaveWorkPayload) => void
  status: 'Pending' | 'Saving' | 'Done' | 'Error' | 'Deleting' | undefined
  error?: RequestError
}

const ConstructionForm: FC<ConstructionFormProps> = ({
  condominiumId,
  initialValues,
  onSave,
  status
}) => {
  const isVertical = useIsCondominioVertical()

  const { history } = useRouter()
  const payload = useTypedSelector(({ auth }) => auth.tokenPayload)
  const work = useTypedSelector(({ work }) => work.items['new'])

  const [street, setStreet] = useState<Street>()
  const [block, setBlock] = useState<Block>()
  const [lot, setLot] = useState<Lot>()
  const [workStatus, setWorkStatus] = useState<{
    value: WorkStatus
    status: string
  }>()
  const [stage, setStage] = useState<1 | 2 | 3>(1)
  const [, setTipoProcesso] = useState<any>()

  useWhenDone('work', 'new', ({ previousStatus }) => {
    if (previousStatus === 'Saving' && !work?.possuiAutor) setStage(3)
    else if (previousStatus === 'Saving' && work?.possuiAutor)
      history.push(`/work/${work?.id}`)
  })

  const isAdmin = payload && payload.role === 'Admin'

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    values
  } = useFormik<SaveWorkPayload>({
    initialValues: initialValues || {
      loteId: '',
      condominioId: condominiumId,
      status: workStatus !== undefined ? workStatus.value : WorkStatus.Invalid,
      areaAConstruir: 0,
      areaConstruida: 0,
      areaTerreno: 0,
      areaTotal: 0,
      numeroPavimento: 0,
      codigo: undefined,
      tipoProcesso: 0
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (!isAdmin) values.status = WorkStatus.Created

      onSave({ ...values })
    },
    validationSchema
  })

  const isSaving = status === 'Saving'

  if (initialValues && !workStatus)
    setWorkStatus({ value: initialValues.status, status: '' })
  if (initialValues && !lot) {
    setLot(initialValues.lote)
    initialValues.lote && setBlock(initialValues.lote.quadra)
    initialValues.lote && setStreet(initialValues.lote.quadra.rua)
  }

  if (initialValues && initialValues.lote && !values.loteId) {
    values.loteId = initialValues.lote.id
  }

  const FormBody = (
    <Box hidden={stage !== 1}>
      {isAdmin && (
        <FormItem
          label='Status'
          hasError={getErrorVisibility(errors.status, touched.status)}
          message={getErrorMessage(errors.status, touched.status)}
        >
          <Autocomplete
            disableClearable
            value={workStatus}
            id='statusSelect'
            size='small'
            onChange={(event, newValue) => {
              newValue && setWorkStatus(newValue)
              newValue && setFieldValue('status', newValue.value)
            }}
            options={workStatusOptions}
            getOptionLabel={option => `${option?.status}`}
            renderInput={params => (
              <TextField
                error={getErrorVisibility(errors.status, touched.status)}
                placeholder='Selecione um status'
                {...params}
                variant='outlined'
              />
            )}
          ></Autocomplete>
        </FormItem>
      )}

      <FormItem
        label='Categoria'
        hasError={getErrorVisibility(errors.tipoProcesso, touched.tipoProcesso)}
        message={'Obrigatório'}
      >
        <Autocomplete
          id='statusSelect'
          size='small'
          onChange={(event, newValue) => {
            setTipoProcesso(newValue)
            setFieldValue('tipoProcesso', newValue?.value)
          }}
          options={tipoProcessoOptions}
          getOptionLabel={option => `${option?.nome}`}
          renderInput={params => (
            <TextField
              error={getErrorVisibility(
                errors.tipoProcesso,
                touched.tipoProcesso
              )}
              placeholder='Selecione um status'
              {...params}
              variant='outlined'
            />
          )}
        ></Autocomplete>
      </FormItem>

      {initialValues ? (
        <FormItem
          label='Código'
          hasError={getErrorVisibility(errors.codigo, touched.codigo)}
          message={getErrorMessage(errors.codigo, touched.codigo)}
        >
          <Input
            placeholder='Insira o código do processo'
            name='codigo'
            type='number'
            min='0'
            step='.01'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.codigo}
          />
        </FormItem>
      ) : null}

      <FormItem
        label={isVertical ? 'Unidade' : 'Rua'}
        hasError={getErrorVisibility(errors.loteId, touched.loteId)}
        message={getErrorMessage(errors.loteId, touched.loteId)}
      >
        <StreetSelect
          condominiumId={condominiumId}
          selected={street}
          setSelected={setStreet}
          errorStatus={getErrorVisibility(errors.loteId, touched.loteId)}
        />
      </FormItem>

      <FormItem
        label={isVertical ? 'Andar' : 'Quadra'}
        hasError={getErrorVisibility(errors.loteId, touched.loteId)}
        message={getErrorMessage(errors.loteId, touched.loteId)}
      >
        <BlockSelect
          streetId={street ? street.id : ''}
          selected={block}
          setSelected={setBlock}
          errorStatus={getErrorVisibility(errors.loteId, touched.loteId)}
        />
      </FormItem>
      <FormItem
        label={isVertical ? 'Número' : 'Lote'}
        hasError={getErrorVisibility(errors.loteId, touched.loteId)}
        message={getErrorMessage(errors.loteId, touched.loteId)}
      >
        <LotSelect
          blockId={block ? block.id : ''}
          selected={lot}
          setSelected={value => {
            setLot(value)
            setFieldValue('loteId', value ? value.id : '')
          }}
          errorStatus={getErrorVisibility(errors.loteId, touched.loteId)}
        />
      </FormItem>
    </Box>
  )

  const FormLotInfo = (
    <Box>
      <FormItem
        label='Área a construir'
        hasError={getErrorVisibility(
          errors.areaAConstruir,
          touched.areaAConstruir
        )}
        message={getErrorMessage(errors.areaAConstruir, touched.areaAConstruir)}
      >
        <Input
          placeholder='Insira a área a construir'
          name='areaAConstruir'
          type='number'
          min='0'
          step='.01'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.areaAConstruir}
        />
      </FormItem>

      <FormItem
        label='Número pavimento'
        hasError={getErrorVisibility(
          errors.numeroPavimento,
          touched.numeroPavimento
        )}
        message={getErrorMessage(
          errors.numeroPavimento,
          touched.numeroPavimento
        )}
      >
        <Input
          placeholder='Insira o número de pavimento'
          name='numeroPavimento'
          type='number'
          min='0'
          step='.01'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.numeroPavimento}
        />
      </FormItem>
    </Box>
  )

  return stage === 3 && work ? (
    <BindLotOwnerToWork id={work.id} />
  ) : (
    <form onSubmit={handleSubmit}>
      <Panel>
        <PanelBody>
          {FormBody}
          {FormLotInfo}
        </PanelBody>
        <Divider />
        <Stack isInline justify='end' align='center' p={3}>
          <Button
            icon='save'
            type='submit'
            variant='primary'
            isLoading={isSaving}
            disabled={isSaving}
          >
            Salvar
          </Button>
        </Stack>
      </Panel>
    </form>
  )
}

const tipoProcessoOptions = [
  {
    value: 10,
    nome: 'Construção'
  },
  {
    value: 20,
    nome: 'Reforma'
  }
]

export default ConstructionForm
