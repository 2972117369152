import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'
import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

export interface TypeChecklistRoutine {
  id: string
  nome: string
  tipoItemChecklistRotinaId?: string
  condominioId: string
  dataProximaVisita: string | null
  itemsChecklistRotina: {
    id: string
    nome: string
  }[]
  itemsChecklist: {
    id: string
    nome: string
  }[]

  tipoRotina: string | number
}
export interface EventsExecutionRoutinesUpload {
  nomeOriginal: string
  descricao: string
  pessoaId: string
  uploadDate: string
  url: string
  extensao: string
}
export interface EventsExecutionRoutinesByWorkId {
  id: string
  rotinaId: string
  obraId: string
  pessoaId: string
  dataRotinaId: string
  observacao: string
  proximaVisita: string
  proximaVisitaFormatada?: string
  nome: string
  tipoRotina: number | string
  tipoItemChecklistRotina: [
    {
      id: string
      nome: string
      dataProximaVisita: string
      itemsChecklistRotina: [
        {
          id: string
          nome: string
          status: number
          uploads: EventsExecutionRoutinesUpload[]
        }
      ]
    }
  ]
}
export interface EditTypeChecklistRoutine {
  id: string
  nome: string
  tipoItemChecklistRotinaId?: string
  dataProximaVisita: string | null
  itemsChecklistRotina: {
    id: string
    nome: string
  }[]
}
export type FetchTypeChecklistRoutineOptions = PaginatedEndpointOptions<
  TypeChecklistRoutine
>

export type FetchTypeChecklistRoutineResponse = PaginatedEndpointResponse<
  TypeChecklistRoutine
>
export type FetchEventsExecutionRoutinesByWorkIdOptions = PaginatedEndpointOptions<
  EventsExecutionRoutinesByWorkId
>

export type FetchEventsExecutionRoutinesByWorkIdResponse = PaginatedEndpointResponse<
  EventsExecutionRoutinesByWorkId
>

/**
 * Carrega lista de tipo de item checklist de rotina.
 */
export async function fetchTypesChecklistRoutine(
  options?: FetchTypeChecklistRoutineOptions
) {
  try {
    const { data } = await api.get<FetchTypeChecklistRoutineResponse>(
      'Rotina/BuscaTipoItemChecklist',
      {
        params: options
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
/**
 * Carrega lista de tipo de item checklist de rotina paginadas e filtradas pela query.
 */
export async function fetchTypesChecklistRoutineByName(
  options?: FetchTypeChecklistRoutineOptions
) {
  try {
    const { data } = await api.get<FetchTypeChecklistRoutineResponse>(
      'Rotina/BuscaTipoItemChecklistPorNome',
      {
        params: {
          condominioId: options?.condominioId,
          nome: options?.nome
        }
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
/**
 * Carrega um tipo de item checklist de rotina pelo id.
 */
export async function fetchTypeChecklistRoutine(id: string) {
  try {
    const { data } = await api.get<TypeChecklistRoutine>(
      `Rotina/BuscaTipoItemChecklistPorId/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Busca Eventos de ExecucaoRotinas por obraId.
 */
export async function fetchEventsExecutionRoutinesByWorkId(
  options: FetchEventsExecutionRoutinesByWorkIdOptions
) {
  try {
    const { data } = await api.get<
      FetchEventsExecutionRoutinesByWorkIdResponse
    >(`Rotina/BuscaEventosExecucaoRotinasPorRotinaId/`, { params: options })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveTypeChecklistRoutinePayload = SaveResourcePayload<
  TypeChecklistRoutine
>
export type EditTypeChecklistRoutinePayload = SaveResourcePayload<
  EditTypeChecklistRoutine
>

/**
 * Salva um tipo de item checklist de rotina, se houver id altera o existente.
 */
export async function saveTypeChecklistRoutine(
  typeChecklistRoutine: SaveTypeChecklistRoutinePayload
) {
  try {
    const { data } = await api.post<TypeChecklistRoutine>(
      'Rotina/CriaTipoChecklistRotina',
      typeChecklistRoutine
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
export async function editTypeChecklistRoutine(
  EditTypeChecklistRoutine: EditTypeChecklistRoutinePayload
) {
  try {
    const { data } = await api.put<EditTypeChecklistRoutine>(
      'Rotina/EditaTipoChecklistRotina',
      EditTypeChecklistRoutine
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

// export const editTypeChecklistRoutine = (id: string) =>
//   update<TypeChecklistRoutine>(`Rotina/EditaTipoChecklistRotina/${id}`)
/**
 * Apaga um tipo de item checklist de rotina pelo id.
 */
export async function deleteTypeChecklistRoutine(id: string) {
  try {
    const { data } = await api.delete<TypeChecklistRoutine>(
      `Rotina/DeletaTipoItemChecklistRotina/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
export async function deleteItemTypeChecklistRoutine(id: string) {
  try {
    const { data } = await api.delete<TypeChecklistRoutine>(
      `Rotina/DeletaItemChecklistRotina/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
