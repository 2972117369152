import { CONDOMINIOS_VERTICAIS } from 'shared/condominios-verticais'
import useTypedSelector from './useTypedSelector'

function useIsCondominioVertical() {
  const condominioId = useTypedSelector(({ auth }) => auth.currentCondominium)

  return CONDOMINIOS_VERTICAIS.includes(condominioId ?? '')
}

export default useIsCondominioVertical
