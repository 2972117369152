import { normalize, schema } from 'normalizr'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  WorkEvent,
  WorkEventOptions,
  deleteWorkEvent,
  fetchEvents,
  fetchWorkEvents
} from 'workEvent/api/workEventApi'
import {
  clearWorkEventFail,
  clearWorkEventStarted,
  clearWorkEventSuccess,
  loadWorksEventByFilterStarted,
  loadWorksEventFail,
  loadWorksEventStarted,
  loadWorksEventSuccess
} from 'workEvent/reducer/workEventReducer'

import { PayloadAction } from '@reduxjs/toolkit'
import { RequestError } from 'types/global'

export const workEventSchema = new schema.Entity<WorkEvent>('workEvent')

export function* handleLoadWorksEvent(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchWorkEvents, action.payload)

    const { entities } = normalize(response, [workEventSchema])

    return yield put(
      loadWorksEventSuccess({
        result: entities.workEvent || [],
        forWork: action.payload
      })
    )
  } catch (error) {
    return yield put(loadWorksEventFail(error))
  }
}

export function* handleLoadWorksEventByFilter(
  action: PayloadAction<WorkEventOptions | undefined>
) {
  try {
    const response = yield call(fetchEvents, action.payload)

    const { entities } = normalize(response.result, [workEventSchema])

    return yield put(
      loadWorksEventSuccess({
        result: entities.workEvent || [],
        forWork: action.payload?.obraId ?? 'new'
      })
    )
  } catch (error) {
    return yield put(loadWorksEventFail(error))
  }
}

export function* handleClearWorkEvent({
  payload
}: PayloadAction<{
  workId: string
  eventId: string
}>) {
  try {
    yield call(deleteWorkEvent, {
      eventoId: payload.eventId,
      processoId: payload.workId
    })

    yield put(clearWorkEventSuccess(payload))

    return yield put(loadWorksEventStarted(payload.workId))
  } catch (error) {
    return yield put(
      clearWorkEventFail({
        forItem: payload.eventId,
        error: error as RequestError
      })
    )
  }
}

function* workEventSaga() {
  yield takeLatest(loadWorksEventStarted.type, handleLoadWorksEvent)
  yield takeLatest(
    loadWorksEventByFilterStarted.type,
    handleLoadWorksEventByFilter
  )
  yield takeLatest(clearWorkEventStarted.type, handleClearWorkEvent)
}

export default workEventSaga
