import { PayloadAction } from '@reduxjs/toolkit'
import {
  FetchNonComplianceTypesOptions,
  NonComplianceType,
  SaveNonComplianceTypePayload,
  deleteNonComplianceType,
  fetchNonComplianceType,
  fetchNonComplianceTypes,
  fetchNonComplianceTypesFromCondominium,
  saveNonComplianceType
} from 'nonComplianceType/api/nonComplianceTypeApi'
import {
  deleteNonComplianceTypeFail,
  deleteNonComplianceTypeStarted,
  deleteNonComplianceTypeSuccess,
  loadNonComplianceTypesFail,
  loadNonComplianceTypesFromCondominiumFail,
  loadNonComplianceTypesFromCondominiumStarted,
  loadNonComplianceTypesFromCondominiumSuccess,
  loadNonComplianceTypesStarted,
  loadNonComplianceTypesSuccess,
  loadSingleNonComplianceTypeFail,
  loadSingleNonComplianceTypeStarted,
  loadSingleNonComplianceTypeSuccess,
  saveNonComplianceTypeFail,
  saveNonComplianceTypeStarted,
  saveNonComplianceTypeSuccess
} from 'nonComplianceType/reducers/nonComplianceTypeReducer'
import { normalize, schema } from 'normalizr'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { Pagination } from 'types/global'

export const nonComplianceTypeSchema = new schema.Entity<NonComplianceType>(
  'nonComplianceType'
)

export function* handleLoadNonComplianceTypes(
  action: PayloadAction<FetchNonComplianceTypesOptions | undefined>
) {
  if (action.payload === '' || action.payload === undefined) return

  try {
    const response = yield call(fetchNonComplianceTypes, action.payload)

    const { entities } = normalize(response.result, [nonComplianceTypeSchema])

    const pagination: Pagination = {
      current: response.page,
      pageSize: response.pageSize,
      total: response.totalItemCount
    }

    return yield put(
      loadNonComplianceTypesSuccess({
        result: entities.nonComplianceType || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadNonComplianceTypesFail(error))
  }
}

export function* handleLoadNonComplianceTypesFromCondominium(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(
      fetchNonComplianceTypesFromCondominium,
      action.payload
    )

    const { entities } = normalize(response, [nonComplianceTypeSchema])

    return yield put(
      loadNonComplianceTypesFromCondominiumSuccess({
        result: entities.nonComplianceType || [],
        forCondominium: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadNonComplianceTypesFromCondominiumFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleNonComplianceType(
  action: PayloadAction<string>
) {
  if (action.payload === '' || action.payload === undefined) return

  try {
    const response = yield call(fetchNonComplianceType, action.payload)
    const { entities } = normalize(response, nonComplianceTypeSchema)

    return yield put(
      loadSingleNonComplianceTypeSuccess(
        entities.nonComplianceType[response.id]
      )
    )
  } catch (error) {
    return yield put(
      loadSingleNonComplianceTypeFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveNonComplianceType({
  payload
}: PayloadAction<SaveNonComplianceTypePayload>) {
  try {
    const response = yield call(saveNonComplianceType, payload)

    const { entities } = normalize(response, nonComplianceTypeSchema)

    return yield put(
      saveNonComplianceTypeSuccess({
        forItem: payload.id || 'new',
        result: entities.nonComplianceType[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveNonComplianceTypeFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteNonComplianceType({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteNonComplianceType, payload)

    return yield put(deleteNonComplianceTypeSuccess(payload))
  } catch (error) {
    return yield put(
      deleteNonComplianceTypeFail({
        forItem: payload,
        error
      })
    )
  }
}

function* nonComplianceTypeSaga() {
  yield takeLatest(
    loadNonComplianceTypesStarted.type,
    handleLoadNonComplianceTypes
  )
  yield takeLatest(
    loadNonComplianceTypesFromCondominiumStarted.type,
    handleLoadNonComplianceTypesFromCondominium
  )
  yield takeLatest(
    loadSingleNonComplianceTypeStarted.type,
    handleLoadSingleNonComplianceType
  )
  yield takeLeading(
    saveNonComplianceTypeStarted.type,
    handleSaveNonComplianceType
  )
  yield takeLeading(
    deleteNonComplianceTypeStarted.type,
    handleDeleteNonComplianceType
  )
}

export default nonComplianceTypeSaga
