import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export enum UserType {
  Desconhecido = 'Desconhecido',
  Haprojet = 'Haprojet',
  Condomino = 'Condomino',
  Admin = 'Admin'
}

export enum UserWorkProfile {
  Desconhecido = 'Desconhecido',
  Autor = 'AutorProjeto',
  ResponsavelTecnico = 'ResponsavelTecnico',
  Proprietario = 'Proprietario',
  Preposto = 'Preposto'
}

export enum UserCondominiumProfile {
  Desconhecido = 'Desconhecido',
  Administrador = 'Administrador',
  Analista = 'Analista',
  Sindico = 'Sindico',
  Fiscal = 'Fiscal',
  Porteiro = 'Porteiro',
  Gerente = 'Gerente',
  Solicitante = 'Solicitante',
  Operacional = 'Operacional',
  Gestor = 'Gestor'
}

export interface User {
  id: string
  userId: string
  nome: string
  cpf: string
  email: string
  telefone: string
  crea: string
  cau: string
  userName: string
  userType?: UserType
  password: string
  TermoBusca?: string
}

export type FetchUsersOptions = PaginatedEndpointOptions<User>

export type FetchUsersResponse = PaginatedEndpointResponse<User>

/**
 * Carrega lista de usuarios paginadas e filtradas pela query.
 */
export async function fetchUsers(options?: FetchUsersOptions) {
  try {
    const { data } = await api.get<FetchUsersResponse>('Usuario', {
      params: options
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um usuario pelo id.
 */
export async function fetchUser(id: string) {
  try {
    const { data } = await api.get<User>(`Usuario/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveUserPayload = SaveResourcePayload<User>

/**
 * Salva um usuario, se houver id altera o existente.
 */
export async function saveUser(user: SaveUserPayload) {
  try {
    const request = user.id ? api.put : api.post

    const { data } = await request<User>('Usuario', user)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface ForgotPassword {
  email: string
}

/**
 * ESQUECI MINHA SENHA
 */
export async function forgotPassword(forgotPassword: ForgotPassword) {
  try {
    const { data } = await api.post<User>(
      'Usuario/EsqueceuSenha',
      forgotPassword
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface ResetPassword {
  email: string
  token: string
  password: string
}

/**
 * GERAR NOVA SENHA
 */
export async function resetPassword(resetPassword: ResetPassword) {
  try {
    const { data } = await api.post<User>('Usuario/ResetarSenha', resetPassword)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
