import useTypedSelector from 'common/hooks/useTypedSelector'
import { useQuery } from 'react-query'
import { fetchPermissions } from 'userPermissions/api/permissoes2'

function useGetPermissoes() {
  const condominioId = useTypedSelector(state => state.auth.currentCondominium)
  return useQuery(['permissoes 2.0', condominioId], async () =>
    fetchPermissions(condominioId as string)
  )
}

export default useGetPermissoes
